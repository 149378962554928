<template>
  <BaseLayout />
</template>

<script>
import BaseLayout from "@/components/layout/BaseLayout";
export default {
  name: 'App',
  components: {BaseLayout},
}
</script>

<style lang="scss">
*, *::before, *::after {
  box-sizing: border-box;
}

* {
  font-family: 'Roboto', sans-serif;
}

ul[class], ol[class] {
  padding: 0;
  list-style-type: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

ul, ol {
  padding: 0;
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

input,
button,
textarea,
select {
  font: inherit;
}
</style>