<template>
  <div class="get-ticket__container">
    <p class="get-ticket__title">Вы успешно прошли процедуру бронирования очереди МАПП Торфяновка. Чтобы скачать талон бронирования, нажмите на кнопку "Получить талон"</p>
    <div class="get-ticket__body">
      <div class="get-ticket__qr-container">
        <img src="../../assets/default-qrcode.png" alt="qr-code">
        <p class="get-ticket__qr-text">Наведите считывающее устройтво на QR-код и пройдите по ссылке</p>
      </div>
      <div class="get-ticket__data-wrapper">
        <p class="get-ticket__data-text">Номер бронирования: {{bookingId ?? ''}}</p>
        <p class="get-ticket__data-text">Дата бронирования: {{timeSlot ? getDate() : ''}}</p>
        <p class="get-ticket__data-text">Время бронирования: {{timeSlot ? getTime() : ''}}</p>
        <p class="get-ticket__data-text">ФИО водителя: {{fio ?? ''}}</p>
        <p class="get-ticket__data-text">Категория транспорта: {{transportCategory ?? ''}}</p>
        <p class="get-ticket__data-text">Регистрационный номер: {{autoNumber ?? ''}}</p>
      </div>
    </div>
    <div class="get-ticket__button-wrapper">
      <BaseButton :on-click="getTicketHandler"
                  :is-disabled="false"
                  text="Получить талон" />
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/ui/BaseButton";
export default {
  name: "GetTicketForm",
  components: {BaseButton},
  props: {
    getTicket: {
      type: Function,
      required: true
    },
    fio: {
      type: String,
      required: true,
    },
    autoNumber: {
      type: String,
      required: true,
    },
    transportCategory: {
      type: String,
      required: true,
    },
    queueNumber: {
      type: String,
      required: true,
    },
    converterQrCode: {
      type: String,
      required: true,
    },
    language: {
      type: String,
      required: true,
    },
    borderPointName: {
      type: String,
      required: true,
    },
    timeSlot: {
      type: Object,
      required: true,
    },
    bookingId: {
      type: String,
      required: true
    }
  },
  methods: {
    getDate() {
      const startDate = new Date(this.timeSlot.start);
      return this._showDate(startDate);
    },
    getTime() {
      const startDate = new Date(this.timeSlot.start);
      return this._showTime(startDate);
    },
    getTicketHandler() {
      const startDate = new Date(this.timeSlot.start);
      const endDate = new Date(this.timeSlot.end);
      const data = JSON.stringify({
        borderPointName: this.borderPointName,
        converterQrCode: this.converterQrCode,
        language: this.language,
        queueNumber: this.queueNumber,
        timeSlotTime: `${this._showDate(startDate)} ${this._showTime(startDate)} – ${this._showTime(endDate)}`
      })
      this.getTicket(data);
    },
    _showDate(date) {
      const year = date.getFullYear();
      const day = date.getDate();
      const month = date.getMonth() + 1;
      return (day > 9 ? day : '0' + day) +
          '.' +
          (month > 9 ? month : '0' + month) +
          '.' +
          year;
    },
    _showTime(date) {
      const hour = date.getHours();
      const minutes = date.getMinutes();

      return (hour > 9 ? hour : '0' + hour) + ':' + (minutes > 9 ? minutes : '0' + minutes)
    }
  }
}
</script>

<style scoped lang="scss">
.get-ticket__container {
  display: flex;
  flex-direction: column;
  margin-top: 35px
}
.get-ticket__title {
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;
  color: $blueGrey
}
.get-ticket__body {
  display: flex;
  flex-direction: row;
  align-items: flex-start
}
.get-ticket__data-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  margin-left: 30px
}
.get-ticket__data-text {
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;
  color: $blueGrey;
  margin-bottom: 30px
}
.get-ticket__button-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 50px
}
.get-ticket__qr-container {
  display: flex;
  flex-direction: column;
  max-width: 388px;
  img {
    max-width: 388px;
    max-height: 345px;
  }
}
.get-ticket__qr-text {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  color: $lightGrey;
  text-align: center;
  margin-top: -25px
}
</style>