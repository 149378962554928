<template>
  <div class="container">
    <div class="tab">
      <div @click="showMainDataForm"
           id="main-data-button"
           class="dark-blue-border-bottom">
        <p id="main-data-title"
           class="tab__title blue">Основные данные</p>
      </div>
      <div @click="showSelectDateTimeForm"
           id="select-datetime-button"
           class="border-bottom-none">
        <p id="select-datetime-title"
           class="tab__title grey">Выбор даты/времени</p>
      </div>
      <div @click="showGetTicketForm"
           id="get-ticket-button"
           class="border-bottom-none">
        <p id="get-ticket-title"
           class="tab__title grey">Получение талона</p>
      </div>
    </div>
    <div>
      <div id="main-data" class="block">
        <MainDataForm :save-and-next="saveAndGoToSelectDateTime"/>
      </div>
      <div id="select-datetime" class="hide">
        <SelectDateTimeForm :save-and-next="saveAndGoToGetTicket"
                            :fetchTimeSlots="fetchTimeSlots"
                            :loadTimeSlots="loadTimeSlots"/>
      </div>
      <div id="get-ticket" class="hide">
        <GetTicketForm :fio="fio"
                       :booking-id="this.bookingId"
                       :transportCategory="transportCategory"
                       :autoNumber="autoNumber"
                       :queueNumber="queueNumber"
                       :converterQrCode="converterQrCode"
                       :language="language"
                       :borderPointName="borderPointName"
                       :timeSlot="timeSlot"
                       :getTicket="getTicket"
        />
      </div>
      <BaseAlert :show="show"
                 :type="type"
                 :message="message"/>
    </div>
  </div>
</template>

<script>
import MainDataForm from "@/components/form/MainDataForm";
import SelectDateTimeForm from "@/components/form/SelectDateTimeForm";
import GetTicketForm from "@/components/form/GetTicketForm";

export default {
  name: "TabComponent",
  components: {GetTicketForm, SelectDateTimeForm, MainDataForm},
  data() {
    return {
      token: null,
      username: 'eq_print_service',
      password: 'secret',
      driver: null,
      lockId: null,
      bookingId: null,
      queueNumber: null,
      converterQrCode: null,
      timeSlot: null,
      language: "ENGLISH",
      borderPointName: "МАПП Торфяновка",
      fio: '',
      autoNumber: '',
      transportCategory: '',
      loadTimeSlots: false,
      show: false,
      message: '',
      type: '',
    }
  },
  async created() {
    const response = await fetch("http://10.2.0.120:8892/oauth2/token?grant_type=client_credentials", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Basic ${window.btoa(`${this.username}:${this.password}`)}`,
      },
      body: JSON.stringify({
        username: "q@q.com",
        password: "q"
      })
    });
    const data = await response.json();
    this.token = data.access_token;
  },
  methods: {
    showMainDataForm() {
      this._showForm('main-data');
      this._hideForm('select-datetime');
      this._hideForm('get-ticket');
    },
    showSelectDateTimeForm() {
      if (this.driver === null) return;
      this._showForm('select-datetime');
      this._hideForm('main-data');
      this._hideForm('get-ticket');
    },
    showGetTicketForm() {
      if (this.driver === null || this.lockId === null) return;
      this._showForm('get-ticket');
      this._hideForm('main-data');
      this._hideForm('select-datetime');
    },
    saveAndGoToSelectDateTime(driver) {
      this.driver = driver;
      this.fio = [
        driver.clientLegalEntityInfo.contactPersonLastName,
        driver.clientLegalEntityInfo.contactPersonName,
        driver.clientLegalEntityInfo.contactPersonMiddleName].join(' ');
      this.autoNumber = driver.trailerInfo[0].licensePlate;
      this.transportCategory = driver.trailerInfo[0].trailerType;
      this.showSelectDateTimeForm()
    },

    async fetchTimeSlots(start, end) {
      this.loadTimeSlots = true;
      const periodStart = this._toBackendDateFormat(start, 'T00:00:00');
      const periodEnd = this._toBackendDateFormat(end, 'T23:59:59');
      try {
        const response = await this._postHttpHandler(
            "http://10.2.0.120:9001/api/v1/dictionaries/time-slots",
            JSON.stringify({periodEnd, periodStart}));
        const data = await response.json();
        const result = this._processFreeSlots(data);
        this.loadTimeSlots = false;

        if (result.length > 5) {
          this._showAlert('Загружены слоты за последние пять дней', 'INFO')
          return result.slice(-5);
        }

        return result;

      } catch (e) {
        this.loadTimeSlots = false;
        this._showAlert(e.message, 'ERROR');
      }
    },
    async saveAndGoToGetTicket(reservationSlot) {
      try {
        this.loadTimeSlots = true;
        const lockResponse = await this._postHttpHandler(
            "http://10.2.0.120:9001/api/v1/reserve/locks/" + reservationSlot,
            null);
        const lockData = await lockResponse.json();
        this.lockId = lockData.lockId;
        this.driver.timeSlotId = reservationSlot;

        const reserveResponse = await this._postHttpHandler(
            "http://10.2.0.120:9001/api/v1/reserve/reserves/" + this.lockId,
            JSON.stringify(this.driver));
        const reserveData = await reserveResponse.json();
        this.bookingId = reserveData.id;

        const url = `http://10.2.0.120:9001/api/v1/reserve/reserves?borderPointId=${this.driver.borderPointId}&reserveId=${this.bookingId}`;
        const reserveInfoResponse = await this._getHttpHandler(url);
        const reserveInfoData = await reserveInfoResponse.json();
        this.queueNumber = reserveInfoData[0].queueNumber;
        this.converterQrCode = reserveInfoData[0].qrCode;
        this.timeSlot = reserveInfoData[0].timeSlot;
        this.loadTimeSlots = false;

        this.showGetTicketForm()
      } catch (e) {
        this.loadTimeSlots = false;
        this._showAlert(e.message, 'ERROR');
      }
    },
    async getTicket(data) {
      try {
        const response = await this._postHttpHandler("http://10.2.0.120:9002/api/v1/print/generate-ticket", data);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank")
      } catch (e) {
        this._showAlert(e.message, 'ERROR');
      }
    },

    _processFreeSlots(rawFreeSlots) {
      let processFreeSlots = [];
      rawFreeSlots.forEach(row => {
        let fromDate = new Date(row.dateTimeFrom);
        let slot = {
          id: row.id,
          time: this._toTimeFormat(fromDate.getHours(), fromDate.getMinutes())
        };
        let found = processFreeSlots.find(item => item.date === this._toBackendDateFormat(fromDate));
        if (!found) {
          processFreeSlots.push({
            date: this._toBackendDateFormat(fromDate),
            freeSlots: [slot]
          })
        } else {
          found.freeSlots.push(slot);
        }
      });
      return processFreeSlots;
    },
    _toTimeFormat(hours, minutes) {
      const formatHours = hours > 9 ? hours : '0' + hours.toString();
      const formatMinutes = minutes > 9 ? minutes : '0' + minutes.toString();
      return formatHours + ':' + formatMinutes;
    },
    _toBackendDateFormat(date, postfix) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return year + '-' + (month > 9 ? month : '0' + month.toString()) + '-' + (day > 9 ? day : '0' + day) + (postfix ?? 'T00:00:00');
    },
    _processError(code, text) {
      const messages = {
        401: "В доступе отказано",
        403: "Недостаточно прав",
        500: "Ошибка сервера"
      }
      return messages[code] ?? (text ? text.message : 'Неизвестная ошибка');
    },
    _showAlert(message, type) {
      const typeToClassMap = {
        "WARNING": 'alert-warning',
        "INFO": 'alert-info',
        "ERROR": 'alert-error',
      }
      this.message = message;
      this.type = typeToClassMap[type];
      this.show = true;
      setInterval(() => {
        this.show = false;
      }, 5000)
    },
    _showForm(classId) {
      document.getElementById(classId).classList.add('block');
      document.getElementById(classId).classList.remove('hide');
      document.getElementById(classId + '-button').classList.add('dark-blue-border-bottom');
      document.getElementById(classId + '-button').classList.remove('border-bottom-none');
      document.getElementById(classId + '-title').classList.add('blue');
      document.getElementById(classId + '-title').classList.remove('grey');
    },
    _hideForm(classId) {
      document.getElementById(classId).classList.add('hide');
      document.getElementById(classId).classList.remove('block');
      document.getElementById(classId + '-button').classList.add('border-bottom-none');
      document.getElementById(classId + '-button').classList.remove('dark-blue-border-bottom');
      document.getElementById(classId + '-title').classList.add('grey');
      document.getElementById(classId + '-title').classList.remove('blue');
    },

    async _getHttpHandler(url) {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`,
        },
      });
      if (!response.ok) {
        const code = response.status;
        const msg = await response.text();
        throw new Error(this._processError(code, msg))
      }
      return response;
    },
    async _postHttpHandler(url, data) {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`,
        },
        body: data
      })
      if (!response.ok) {
        const code = response.status;
        const msg = await response.json();
        throw new Error(this._processError(code, msg))
      }
      return response;
    },
  }
}
</script>

<style scoped lang="scss">
.tab {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid rgba(164, 169, 185, 0.5);
  margin-top: 40px
}

.tab__title {
  padding: 5px 20px;
  font-size: 24px;
  line-height: 28.13px;
  font-weight: 400;
}

.blue {
  color: $blue;
}

.grey {
  color: $blueGrey;
}

.dark-blue-border-bottom {
  border-bottom: 4px solid $darkBlue;
}

.border-bottom-none {
  border-bottom: none;
}
</style>