<template>
  <div class="select-datetime__container">
    <div class="select-datetime__row">
      <p class="select-datetime__label">От</p>
      <Datepicker v-model="startDate"
                  :format="format"
                  locale="ru"
                  cancelText="Отмена"
                  selectText="Выбрать"/>
    </div>
    <div class="select-datetime__row">
      <p class="select-datetime__label">До</p>
      <Datepicker v-model="endDate"
                  :format="format"
                  locale="ru"
                  cancelText="Отмена" s
                  selectText="Выбрать"/>
    </div>
    <BaseButton :on-click="findFreeSlots"
                :is-disabled="isDisabledFindButton"
                text="Найти свободное время" />
  </div>
  <div v-if="loadTimeSlots" style="display: flex; justify-content: center; align-items: center; margin-top: 50px">
    <ScaleLoader :loading="loadTimeSlots" color="#263A62" size="25px"/>
  </div>
  <div id="freeSlotSubform"
       class="free-slot__container hide"
       v-if="!loadTimeSlots"
  >
    <div class="free-slot__items-wrapper"
         v-for="(row, i) in freeSlots"
         :key="i">
      <div class="free-slot__item-header">
        <p class="free-slot__item-header-text">{{ showDate(new Date(row.date)) }}</p>
      </div>
      <div class="free-slot-items-row">
        <div class="free-slot__item"
             v-for="(item, j) in row.freeSlots" :key="item.id"
             :style="[i === activeI && j === activeJ ? {'background': 'rgba(37, 43, 89, 1)'} : {'background': 'rgba(102, 142, 208, 1)'}]"
             @click="selectSlot(row.date, item, i, j)"
        >
          <p class="free-slot__item-text">{{ item.time }}</p>
        </div>
      </div>
    </div>
    <div class="free-slot__btn-wrapper">
      <BaseButton :on-click="save"
                  :is-disabled="isDisabledSaveButton"
                  text="Сохранить и продолжить" />
    </div>
  </div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import BaseButton from "@/components/ui/BaseButton";

export default {
  name: "SelectDateTimeFormComponent",
  components: {BaseButton, Datepicker, ScaleLoader},
  props: {
    saveAndNext: {
      type: Function,
      required: true
    },
    fetchTimeSlots: {
      type: Function,
      required: true
    },
    loadTimeSlots: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return {
      format
    }
  },
  data() {
    return {
      activeI: null,
      activeJ: null,
      startDate: '',
      endDate: '',
      date: '',
      time: '',
      reservationSlot: '',
      freeSlots: [],
    }
  },
  computed: {
    isDisabledFindButton() {
      return !this.startDate || !this.endDate;
    },
    isDisabledSaveButton() {
      return !this.date || !this.time;
    }
  },
  methods: {
    findFreeSlots() {
      this.fetchTimeSlots(this.startDate, this.endDate)
          .then(r => {
            this.freeSlots = r;
            document.getElementById("freeSlotSubform").classList.remove('hide');
          });
    },
    save() {
      this.saveAndNext(this.reservationSlot);
    },
    selectSlot(date, slot, i, j) {
      this.activeI = i;
      this.activeJ = j;
      this.date = date;
      this.time = slot.time;
      this.reservationSlot = slot.id;
    },
    showDate(date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      return (day > 9 ? day : '0' + day) +
          '.' +
          (month > 9 ? month : '0' + month.toString());
    },
  }
}
</script>

<style scoped lang="scss">
.select-datetime__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 35px
}
.select-datetime__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 25%;
}
.select-datetime__label {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  margin-right: 10px
}
.free-slot__container {
  margin-top: 30px;
}
.free-slot__items-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
}
.free-slot__item-header {
  border-bottom: 1px solid $grey;
  width: 100%;
  margin-bottom: 10px
}
.free-slot__item-header-text {
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  color: $blue;
  padding-bottom: 15px
}
.free-slot-items-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.free-slot__item {
  width: 100px;
  height: 47px;
  color: $white;
  margin-left: 30px;
  margin-bottom: 15px;
  border-radius: 7px;
  padding: 12px 32px 12px;
  cursor: pointer;
}
.free-slot__item-text {
  text-align: center;
}
.free-slot__btn-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 50px
}
</style>