<template>
  <div class="main-data__container">
    <div class="main-data__row">
      <div class="main-data__input-wrapper">
        <p class="main-data__label">ФИО водителя</p>
        <select v-model="fio"
                class="main-data__input"
                @change="selectDriver">
          <option v-for="(item, index) in fios"
                  :key="index">{{ item }}</option>
        </select>
      </div>
      <div class="main-data__input-wrapper">
        <label for="organization"
               class="main-data__label">Организация</label>
        <input type="text"
               id="organization"
               name="organization"
               class="main-data__input"
               v-model="organization" disabled>
      </div>
    </div>
    <div class="main-data__row">
      <div class="main-data__input-wrapper">
        <label for="phone"
               class="main-data__label">Телефон</label>
        <input type="text"
               id="phone"
               name="phone"
               class="main-data__input"
               v-model="phone" disabled>
      </div>
      <div class="main-data__input-wrapper">
        <label for="address"
               class="main-data__label">Адрес</label>
        <input type="text"
               id="address"
               name="address"
               class="main-data__input"
               v-model="address" disabled>
      </div>
    </div>
    <div class="main-data__row">
      <div class="main-data__input-wrapper">
        <label for="inn"
               class="main-data__label">ИНН организации</label>
        <input type="text"
               id="inn"
               name="inn"
               class="main-data__input"
               v-model="inn" disabled>
      </div>
      <div class="main-data__input-wrapper">
        <label for="kpp"
               class="main-data__label">КПП организации</label>
        <input type="text"
               id="kpp"
               name="kpp"
               class="main-data__input"
               v-model="kpp" disabled>
      </div>
    </div>
    <div class="main-data__row">
      <div class="main-data__input-wrapper">
        <label for="transport_category"
               class="main-data__label">Категория транспорта</label>
        <input type="text"
               id="transport_category"
               name="transport_category"
               class="main-data__input"
               v-model="transport_category"
               disabled>
      </div>
      <div class="main-data__input-wrapper">
        <label for="auto_number"
               class="main-data__label">Номер автотранспорта</label>
        <input type="text"
               id="auto_number"
               name="auto_number"
               class="main-data__input"
               v-model="auto_number"
               disabled>
      </div>
    </div>
    <div class="main-data__button-wrapper">
      <BaseButton :on-click="save"
                  :is-disabled="isDisabled"
                  text="Сохранить и продолжить" />
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/ui/BaseButton";
export default {
  name: "MainDataFormComponent",
  components: {BaseButton},
  props: {
    saveAndNext: {
      type: Function,
      required: true,
    }
  },
  data() {
    return {
      borderPointId: null,
      fio: '',
      organization: '',
      phone: '',
      address: '',
      inn: '',
      kpp: '',
      transport_category: '',
      auto_number: '',
      driver: null,
      fios: [
        "Иванов Иван Иванович", "Петров Петр Петрович"
      ],
      drivers: [
          {
          borderPointId: "eea170ac-8265-4be1-9a5a-2531c9dbb8a2",
          cargoExportInfo: {
            cargoCompound: true,
            customsDeclarationNumbers: [
              4321
            ]
          },
          clientLegalEntityInfo: {
            addressCorrespondence: "Москва, 117312, ул. Вавилова, д. 19",
            addressLegal: "Россия, Москва, 117312, ул. Вавилова, д. 19",
            contactPersonEmail: "ivanov.ivan@romashka.com",
            contactPersonLastName: "Иванов",
            contactPersonMiddleName: "Иванович",
            contactPersonName: "Иван",
            contactPersonPhone: "+79031111111",
            contactPersonPosition: "секретарь",
            countryCode: 643,
            emailCorrespondence: "rogaikopyta@romashka.ru",
            entitledPersonDocumentNumber: "№32423",
            entitledPersonFirstName: "Сидор",
            entitledPersonLastName: "Сидоров",
            entitledPersonMiddleName: "Сидорович",
            entitledPersonPosition: "директор",
            inn: 1234567890,
            kpp: 1234567899,
            legalFullName: "OOO \"Ромашка\"",
            notificationPreference: "EMAIL",
            ogrn: 1217766000011,
            phone: "+79061111112",
            regionCode: 77
          },
          drivers: [
            {
              duls: [
                {
                  countryCode: 643,
                  docType: "PASSPORT",
                  firstNameLatin: "Vasiliy",
                  lastNameLatin: "Chapaev",
                  middleNameLatin: "Ivanovich",
                  number: "string",
                  validUntil: "2021-12-31"
                }
              ],
              email: "ivanov.ivan@romashka.com",
              firstName: "Сидор",
              lastName: "Сидоров",
              middleName: "Сидорович",
              notificationPreference: "EMAIL",
              phone: "+79031111111"
            }
          ],
          licenseInfo: [
            {
              issueDate: "2021-12-31",
              issuer: "таможня д. Кукуево",
              owner: 1235,
              registrationNumber: 12345,
              validSince: "2021-12-31",
              validUntil: "2022-12-31"
            }
          ],
          timeSlotId: null,
          trailerInfo: [
            {
              countryCode: 643,
              licensePlate: "E777KX99",
              trailerType: "SEMI"
            }
          ],
          vehicleInfo: {
            category: "HEAVY",
            countryCode: 643,
            frontLicensePlate: "E777KX99",
            manufacturer: "Volvo",
            model: "460",
            seats: 3,
            vin: "KL1UF756E6B195928"
          }
        }]
    }
  },
  computed: {
    isDisabled() {
      return !this.fio;
    }
  },
  methods: {
    selectDriver() {
      const driver = this.drivers[0];
      this.driver = driver;
      this.borderPointId = driver.borderPointId;
      this.organization = driver.clientLegalEntityInfo.legalFullName;
      this.phone = driver.clientLegalEntityInfo.phone;
      this.address = driver.clientLegalEntityInfo.addressLegal;
      this.inn = driver.clientLegalEntityInfo.inn;
      this.kpp = driver.clientLegalEntityInfo.kpp;
      this.transport_category = driver.trailerInfo[0].trailerType;
      this.auto_number = driver.trailerInfo[0].licensePlate;
    },
    save() {
      if(this.fio === '' || this.borderPointId === null) return;
      this.saveAndNext(this.driver);
    }
  }
}
</script>

<style scoped lang="scss">
.main-data__container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.main-data__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
}
.main-data__input-wrapper {
  display: flex;
  flex-direction: column;
  width: 45%
}
.main-data__label {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 10px;
}
.main-data__input {
  padding: 8px 12px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
  background-color: $white;
  border: 1px solid $lightGrey;
  border-radius: 5px;
  width: 100%;
  max-height: 47px;
  &:focus {
    outline: 2px solid $darkBlue;
    outline-offset: -1px;
  }
}
.main-data__button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
}
</style>