<template>
  <footer/>
</template>

<script>
export default {
  name: "TheFooter"
}
</script>

<style scoped>

</style>