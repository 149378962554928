<template>
<div class="layout">
  <TheHeader />
  <BookingMappContainer />
  <TheFooter />
</div>
</template>

<script>
import TheHeader from "@/components/layout/TheHeader";
import BookingMappContainer from "@/components/container/BookingMappContainer";
import TheFooter from "@/components/layout/TheFooter";

export default {
  name: "BaseLayout",
  components: {TheFooter, BookingMappContainer, TheHeader}
}
</script>

<style scoped>
.layout {

}
</style>