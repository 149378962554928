<template>
  <div class="alert-container" v-show="show">
    <div :class="'alert alert-box ' + type" role="alert">
      {{message}}
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseAlert",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}
.alert-error {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.alert-info {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}
.alert-container {
  position: fixed;
  bottom: 15px;
  right: 15px;
  transition: height .2s;
}
.alert-box {
  margin: auto;
  max-width: 800px;
  -webkit-box-shadow: 0 0 12px 0 rgba(34, 60, 80, 0.16);
  -moz-box-shadow: 0 0 12px 0 rgba(34, 60, 80, 0.16);
  box-shadow: 0 0 12px 0 rgba(34, 60, 80, 0.16);
}
</style>