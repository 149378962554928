<template>
  <header class="header">
    <div class="header__title container">
      <p class="header__calendar-icon" />
      <p class="header__title_text">Бронирование автомобильной очереди на МАПП Торфяновка</p>
    </div>
  </header>
</template>

<script>
export default {
  name: "TheHeader"
}
</script>

<style scoped lang="scss">
.header {
  background: $darkBlueGrey;
}
.header__title {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 20px;
  margin: auto
}
.header__title_text {
  margin-left: 20px;
  color: $white;
  font-size: 30px;
  line-height: 35.16px;
  font-weight: 700;
}
.header__calendar-icon {
  width: 47px;
  height: 47px;
  background-image: url("~@/assets/calendar.svg");
  background-color: transparent;
  border: none;
}
</style>