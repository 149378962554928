<template>
  <button class="btn" @click="onClick" :disabled="isDisabled">
    {{text}}
  </button>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    text: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.btn {
  border-radius: 7px;
  display: block;
  background: $darkBlue;
  color: $white;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  padding: 12px 32px 12px;
  max-width: 300px;
  max-height: 47px;
  cursor: pointer;
}
</style>